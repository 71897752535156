import React from "react"
import HouseListing from "./houseListing"

function HouseListingSection({ houseListings }) {
  return (
    <div className='w-11/12 lg:w-10/12 mx-auto py-20'>
      <p className='lg:text-4xl text-3xl font-bold leading-tight capitalize pb-5'>
        Todays Featured <span className='text-[#B36824]'>Listings</span>
      </p>
      <hr className='h-[10px] w-[200px] bg-[#B36824] border border-[#B36824] mb-10' />

      <div className='flex flex-col lg:flex-row gap-10 py-10'>
        {houseListings.slice(0, 3).map((house, index) => (
          <div className='lg:w-4/12'>
            <HouseListing key={index} house={house} />
          </div>
        ))}
      </div>

      <div className='flex justify-center'>
        <button
          onClick={() => {
            window.location.href = "/houses"
          }}
          className='py-2 px-4 text-white text-sm text-left rounded bg-[#B36824]'
        >
          See All Listings
        </button>
      </div>
    </div>
  )
}

export default HouseListingSection
