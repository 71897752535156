import React from "react"
import NavTab from "../components/navtab"
import Footer from "../components/footer"
import user from "../img/user.jpg"
import user1 from "../img/team1.jpg"
import user2 from "../img/team2.jpeg"
import team from "../img/team.jpg"
import logo1 from "../img/partner1.png"
import logo2 from "../img/partner2.png"
import logo3 from "../img/partner3.png"

function About() {
  return (
    <div className='bg-gray-50'>
      <div className='bg-white'>
        <NavTab />
      </div>

      <div className='w-11/12 lg:w-10/12 mx-auto py-10'>
        <div className='pb-20'>
          <p className='lg:text-4xl text-4xl font-bold leading-tight capitalize mb-5'>About Us</p>
          <hr className="h-[10px] w-[150px] bg-[#B36824] border border-[#B36824] mb-10"/>

          <p>
            Legal Registration SharesIn Properties Limited is a duly registered company under the Patents and Companies
            Registration and Agency (PACRA) under the Zambian law. SharesIn Properties Limited has its headquarters in
            Lusaka, Zambia.
          </p>
        </div>

        <div className='pb-20'>
          <p className='lg:text-4xl text-4xl font-bold leading-tight capitalize mb-5'>Our Team</p>
          <hr className="h-[10px] w-[150px] bg-[#B36824] border border-[#B36824] mb-10"/>
          <p>
            Meet our experienced and knowledgeable team, we are passionate about helping you achieve your real estate
            goals and ensuring a smooth and successful transaction.
          </p>

          <div className='flex flex-col lg:flex-row my-5 gap-10 py-10 justify-around'>

            <div className='w-full lg:w-4/12'>
              <img className='lg:w-full lg:h-64 object-cover mx-auto' src={user2} alt='user' />
              <p className='font-semibold mt-4 px-2'>Executive Director</p>
              <p className="px-2">Mr Chola Mfula</p>
            </div>

            <div className='w-full lg:w-4/12'>
              <img className='lg:w-full lg:h-64 object-cover mx-auto' src={user1} alt='user' />
              <p className='font-semibold mt-4 px-2'>Business Development and Property Analyst</p>
              <p className="px-2">Mr Harrison Musakalu</p>
            </div>

            
            <div className='w-full lg:w-4/12'>
              <img className='lg:w-full lg:h-64 object-cover mx-auto' src={team} alt='user' />
              <p className='font-semibold mt-4 px-2'>Legal Team Lead</p>
              <p className="px-2">Ms Mwansa Nkonde</p>
            </div>

            
            <div className='w-full lg:w-4/12'>
              <img className='lg:w-full lg:h-64 object-cover mx-auto' src={team} alt='user' />
              <p className='font-semibold mt-4 px-2'>Property Analyst</p>
              <p className="px-2">Mr Musenge Musenge</p>
            </div>
          </div>
        </div>

        <div className='pb-20'>
          <p className='lg:text-4xl text-4xl font-bold leading-tight capitalize mb-5'>Our Partners & Associations</p>
          <hr className="h-[10px] w-[150px] bg-[#B36824] border border-[#B36824] mb-10"/>
          <p>
            At SharesIn Properties Limited, we're proud to team up with exceptional partners to bring you the best
            opportunities. Our network of collaborators enhances our ability to create outstanding real estate
            experiences. Join us in a world where working together makes a big difference. With our trusted partners, we
            open doors to new possibilities, ensuring a brighter future for our clients and investors.
          </p>

          <div className='flex flex-col lg:flex-row justify-around gap-x-10 gap-y-20 py-10'>
            <div className='w-4/5 mx-auto lg:w-1/5'>
              <img src={logo1} alt='logo' />
            </div>

            <div className='w-4/5 mx-auto lg:w-1/5'>
              <img src={logo2} alt='logo' />
            </div>

            <div className='w-4/5 mx-auto lg:w-1/5'>
              <img src={logo3} alt='logo' />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default About
