import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { addResources } from "../redux/resourceSlice"
import { getListing } from "../firebase"
import Banner from "../components/home/banner"
import HouseListingSection from "../components/home/houseListingSection"
import WhyUs from "../components/home/whyUs"
import HowItWorks from "../components/home/howItWorks"
import ContactUs from "../components/home/contactUs"
import Services from "../components/home/services"
import Testimony from "../components/home/testimony"
import BlogSection from "../components/home/blogSection"
import Footer from "../components/footer"
import Partners from "../components/home/partners"
import AboutUs from "../components/home/aboutUs"
import Newsletter from "../components/home/newsletter"
import Loader from "../components/loader"
import EventSection from "../components/home/eventSection"

function Home() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [resourceData, setResourceData] = useState({
    listings: [],
    blogs: [],
    events: [],
  })

  // Get Lisiting Data
  const getResources = async () => {
    setLoading(true)

    try {
      const listingData = await getListing("houses")
      const blogData = await getListing("blogs")
      const eventData = await getListing("events")

      setResourceData((prevData) => ({
        ...prevData,
        blogs: blogData.length > 0 ? blogData : prevData.blogs,
        listings: listingData.length > 0 ? listingData : prevData.listings,
        events: eventData.length > 0 ? eventData : prevData.listings,
      }))

      dispatch(
        addResources({
          listings: listingData,
          blogs: blogData,
          events: eventData,
        })
      )
      setLoading(false)
    } catch (error) {
      console.log("Error: ", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getResources()
  }, [])

  return (
    <div>
      {loading && <Loader />}

      <Banner />

      <Partners />

      <AboutUs />
      <HowItWorks />

      {resourceData.listings.length > 0 && (
        <div className='bg-gray-100'>
          <HouseListingSection houseListings={resourceData.listings} />
        </div>
      )}

      <WhyUs />

      <Services />

      <Newsletter />

      <Testimony />

      {resourceData.blogs.length > 0 && (
        <div className='bg-gray-100'>
          <BlogSection blogResources={resourceData.blogs} />
        </div>
      )}

      {resourceData.events.length > 0 && (
        <div className='bg-gray-100'>
          <EventSection eventResources={resourceData.events} />
        </div>
      )}

      <ContactUs />

      <Footer />
    </div>
  )
}

export default Home
