import React from "react"
import partner1 from "../../img/partner1.png"
import partner2 from "../../img/partner2.png"
import partner3 from "../../img/partner3.png"

function Partners() {
  return (
    <div className='bg-gray-100 py-3'>
      <div className='w-11/12 lg:w-10/12 mx-auto py-5 flex justify-between'>
        <a href='https://www.znbs.co.zm/' target='_blank' rel='noopener noreferrer'>
          <img src={partner1} alt='partnerLogo' className='w-auto lg:h-20 h-[40px]' />
        </a>
        <a href='https://zapoa.org/' target='_blank' rel='noopener noreferrer' >
          <img src={partner2} alt='partnerLogo' className='w-auto lg:h-20 h-[40px]' />
        </a>
        <a href='https://www.ziea.org/' target='_blank' rel='noopener noreferrer'>
          <img src={partner3} alt='partnerLogo' className='w-auto lg:h-20 h-[40px]' />
        </a>
      </div>
    </div>
  )
}

export default Partners
