import React, { useState } from "react"
import { addSubcriber } from "../../firebase"
import { Link } from "react-router-dom"

function Newsletter() {
  const [email, setEmail] = useState("")
  const [isEmailValid, setIsEmailValid] = useState(true)

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // Check for a valid email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isValid = emailRegex.test(email)

    if (isValid) {
      try {
        await addSubcriber(email)
      } catch (err) {
        console.log("error: ", err)
      }

      setEmail("")
    } else {
      setIsEmailValid(false)
    }
  }

  return (
    <div className='bg-[#B36824]'>
      <div className='w-11/12 lg:w-10/12 mx-auto'>
        <div className='w-11/12 lg:w-8/12 py-20'>
          <p className='lg:text-4xl text-4xl font-bold leading-tight text-white capitalize mb-5'>
            Subscribe to our Newsletter
          </p>

          <p className='mb-5 text-white font-light'>
            Get the latest trends, property listings, and special offers delivered to your inbox. Stay informed with
            valuable insights, be the first to know about exclusive promotions, and connect with local events. We
            respect your privacy and your email will only be used for our newsletter, and you can unsubscribe anytime.
            See our terms and conditions{" "}
            <a className='underline' href='/termsandconditions'>
              here
            </a>
          </p>

          <form onSubmit={handleSubmit} className='flex gap-x-5 mt-[50px]'>
            <input
              type='email'
              id='email'
              name='email'
              value={email}
              onChange={handleEmailChange}
              className={`w-full px-3 py-2 rounded`}
              required
            />
            <button type='submit' className='py-2 px-4 text-[#259EDF] text-sm text-left rounded bg-white'>
              Subscribe
            </button>
          </form>

          {!isEmailValid && <p className='text-red-100 text-sm mt-2'>Please enter a valid email address.</p>}
        </div>
      </div>
    </div>
  )
}

export default Newsletter
