import React from "react"
import EventListing from "./eventListing"

function EventSection({ eventResources }) {
  return (
    <div className='py-20'>
      <div className='w-11/12 lg:w-10/12 mx-auto'>
        <p className='lg:text-4xl text-3xl font-bold leading-tight capitalize mb-5'>
          See upcoming <span className='text-[#B36824] leading-tight'>Events</span>
        </p>
        <hr className='h-[10px] w-[200px] bg-[#B36824] border border-[#B36824] mb-10' />

        <div className='flex flex-col lg:flex-row gap-10 py-10'>
          {eventResources.slice(0, 3).map((event, index) => (
            <div className='lg:w-4/12'>
              <EventListing key={index} event={event} />
            </div>
          ))}
        </div>

        <div className='flex justify-center'>
          <button
            onClick={() => {
              window.location.href = "/events"
            }}
            className='py-2 px-4 text-white text-sm text-left rounded bg-[#B36824]'
          >
            See All Events
          </button>
        </div>
      </div>
    </div>
  )
}

export default EventSection
