import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { addHouses } from "../redux/resourceSlice"
import { getListing } from "../firebase"
import HouseListing from "../components/home/houseListing"
import NavTab from "../components/navtab"
import Footer from "../components/footer"
import Loader from "../components/loader"
import { FilterOutlined } from "@ant-design/icons";

function Houses() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [resourceData, setResourceData] = useState([])
  const [minPrice, setMinPrice] = useState(null)
  const [maxPrice, setMaxPrice] = useState(null)
  const [selectedLocations, setSelectedLocations] = useState([])
  const [filtering, setFiltering] = useState(false)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)

  // Get Listing Data
  const getResources = async () => {
    setLoading(true)

    try {
      const blogData = await getListing("houses")
      setResourceData(blogData)
      dispatch(addHouses(blogData))
      setLoading(false)
    } catch (error) {
      console.log("Error: ", error)
      setLoading(false)
    }
  }

  const filterByPrice = (houses) => {
    return houses.filter((house) => {
      const housePrice = parseInt(house.price)
      return (!minPrice || housePrice >= minPrice) && (!maxPrice || housePrice <= maxPrice)
    })
  }

  const filterByLocation = (houses) => {
    if (selectedLocations.length === 0) {
      return houses
    }

    return houses.filter((house) => selectedLocations.includes(house.location))
  }

  useEffect(() => {
    getResources()
  }, [])

  const toggleFilterModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen)
  }

  const handleFilterClick = async () => {
    setFiltering(true)
    try {
      const blogData = await getListing("houses")
      let filteredData = filterByPrice(blogData)
      filteredData = filterByLocation(filteredData)
      setResourceData(filteredData)
    } catch (error) {
      console.log("Error: ", error)
    } finally {
      setFiltering(false)
      setIsFilterModalOpen(false)
    }
  }

  const handleResetClick = () => {
    setMinPrice(null)
    setMaxPrice(null)
    setSelectedLocations([])
    getResources()
  }

  const handleLocationCheckboxChange = (location) => {
    const updatedLocations = [...selectedLocations]
    const index = updatedLocations.indexOf(location)

    if (index === -1) {
      updatedLocations.push(location)
    } else {
      updatedLocations.splice(index, 1)
    }

    setSelectedLocations(updatedLocations)
  }

  return (
    <div className="bg-gray-50">
      <div className="bg-white">
        <NavTab />
      </div>

      <div className="w-11/12 lg:w-10/12 mx-auto py-10">
        <p className="lg:text-4xl text-4xl font-bold leading-tight capitalize mb-5">
          Pick from our list of <span className="text-[#B36824] leading-tight">real estate</span>
        </p>
        <hr className="h-[10px] w-[200px] bg-[#B36824] border border-[#B36824] mb-5" />

        <div className="mb-20 w-full flex justify-end">
          <button onClick={toggleFilterModal} className="bg-[#B36824] text-white text-sm px-5 py-2 rounded">
          <FilterOutlined className="mr-2"/>
            Filter
          </button>
        </div>

        {/* Filter Modal */}
        {isFilterModalOpen && (
          <div className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
            <div className="my-8 border p-6 bg-white rounded-md shadow-md">
              <div className="mb-4">
                <label htmlFor="minPrice" className="block text-gray-600">
                  Min Price:
                </label>
                <input
                  type="number"
                  id="minPrice"
                  className="w-20 px-2 py-1 border rounded-md"
                  value={minPrice || ""}
                  onChange={(e) => setMinPrice(parseInt(e.target.value) || null)}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="maxPrice" className="block text-gray-600">
                  Max Price:
                </label>
                <input
                  type="number"
                  id="maxPrice"
                  className="w-20 px-2 py-1 border rounded-md"
                  value={maxPrice || ""}
                  onChange={(e) => setMaxPrice(parseInt(e.target.value) || null)}
                />
              </div>

              <div className="mb-4">
                <p className="block text-gray-600 mb-2">Filter by Location:</p>
                {resourceData.length > 0 && (
                  <div>
                    {Array.from(new Set(resourceData.map((house) => house.location))).map((location) => (
                      <label key={location} className="flex items-center mb-2">
                        <input
                          type="checkbox"
                          value={location}
                          checked={selectedLocations.includes(location)}
                          onChange={() => handleLocationCheckboxChange(location)}
                          className="mr-2"
                        />
                        <span className="text-gray-800">{location}</span>
                      </label>
                    ))}
                  </div>
                )}
              </div>

              <div className="flex space-x-4">
                <button
                  onClick={handleFilterClick}
                  disabled={filtering}
                  className={`bg-[#B36824] text-white px-4 py-2 rounded-md ${
                    filtering && "opacity-50 cursor-not-allowed"
                  }`}
                >
                  {filtering ? "Filtering..." : "Apply Filter"}
                </button>

                <button onClick={handleResetClick} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md">
                  Reset Filters
                </button>

                <button
                  onClick={() => setIsFilterModalOpen(false)}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <Loader />
        ) : resourceData.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 pb-20">
            {resourceData.map((house, index) => (
              <div key={index}>
                <HouseListing house={house} />
              </div>
            ))}
          </div>
        ) : (
          <div className="h-[50vh]">
            <p>No houses currently available</p>
          </div>
        )}
      </div>

      <Footer />
    </div>
  )
}

export default Houses
