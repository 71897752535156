import React, { useState } from "react"
import "../../index.css"
import { Select, Modal } from "antd"
import NavTab from "../navtab"
import bannerImage from "../../img/banner.jpg"
import emailjs from "@emailjs/browser"
import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

const { Option } = Select

function Banner() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModal2Open, setIsModal2Open] = useState(false)
  const [isModal3Open, setIsModal3Open] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)

      let templateParams = {
        title: formData.name,
        message: `Name: ${formData.name}\n Message: ${formData.message}\n Email: ${formData.email}`,
        id: formData.email,
        email: "admin@sharesinproperties.com",
      }

      await emailjs.send("service_4mxc341", "template_npiqmee", templateParams, "GOt38AnMGYeGsBpE-")

      setFormData({
        name: "",
        email: "",
        message: "",
      })

      setLoading(false)

      alert("Success! Form successfully submitted.")

      //navigate("/admin/newsletter/index")
    } catch (error) {
      console.log("error: ", error, formData)
    }
  }

  const handleOk = (modal) => {
    modal === 1 ? setIsModalOpen(false) : modal === 2 ? setIsModal2Open(false) : setIsModal3Open(false)
  }
  const handleCancel = (modal) => {
    modal === 1 ? setIsModalOpen(false) : modal === 2 ? setIsModal2Open(false) : setIsModal3Open(false)
  }

  const handleOpen = (modal) => {
    modal === 1 ? setIsModalOpen(true) : modal === 2 ? setIsModal2Open(true) : setIsModal3Open(true)
  }

  const modalButtonProps = {
    style: {
      backgroundColor: "#B36824",
    },
  }

  return (
    <div className='flex flex-col bg-[#B36824]'>
      <div className='bg-white'>
        <NavTab />
      </div>

      <Modal
        centered
        title='Sell My Property'
        okButtonProps={modalButtonProps}
        open={isModalOpen}
        onOk={() => handleOk(1)}
        onCancel={() => handleCancel(1)}
      >
        <div className='py-5'>
          <p className='lg:text-2xl text-2xl font-bold leading-tight capitalize mb-3'>How It Works</p>
          <hr className='h-[8px] w-[100px] bg-[#B36824] border border-[#B36824]' />
        </div>

        <div className='py-5'>
          <ol>
            <li className='mb-5'>
              <p className='font-bold'>1. Consultation and Evaluation</p>
              <p>
                Schedule a consultation with our team. We evaluate your property's market value and discuss your goals.
              </p>
            </li>
            <li className='mb-5'>
              <p className='font-bold'>2. Personalized Marketing Strategy</p>
              <p>
                Develop a tailored marketing plan to showcase your property's unique features. List your property across
                multiple platforms to attract potential buyers.
              </p>
            </li>
            <li className='mb-5'>
              <p className='font-bold'>3. Negotiation and Offers</p>
              <p>
                Handle negotiations to secure the best deal for your property. Provide guidance and support throughout
                the offer evaluation process.
              </p>
            </li>
            <li className='mb-5'>
              <p className='font-bold'>4. Closing Process and Successful Sale</p>
              <p>
                Assist with all closing paperwork and legal requirements. Ensure a smooth transaction and celebrate the
                successful sale of your property.
              </p>
            </li>
          </ol>
        </div>

        <div className='w-full px-5 lg:px-10 py-5 bg-[#B36824] my-5'>
          <form onSubmit={handleSubmit}>
            <p className='text-2xl font-semibold text-white capitalize py-2 border-b border-white'>Make an enquiry</p>

            <p className='text-white text-base font-light py-5 mb-5'>
              Save your time and easily rent or sell your property with the lowest commission on the real estate market.
            </p>

            <div className='mb-4'>
              <label htmlFor='name' className='text-xs text-white font-semibold'>
                Name
              </label>
              <input
                type='text'
                id='name'
                name='name'
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='email' className='text-xs text-white font-semibold'>
                Email
              </label>
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='message' className='text-xs text-white font-semibold'>
                Message
              </label>
              <textarea
                id='message'
                name='message'
                rows='4'
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
            <br />
            <button className='py-2 px-4 text-[#B36824] text-sm text-left rounded bg-white'>Submit</button>
          </form>
        </div>

        <p>If you have properties for sale, please feel free to get in touch with us using one of the methods below.</p>
        <p className='text-[#B36824] mt-5'>+260 966805790 | admin@sharesinproperties.com</p>
      </Modal>

      <Modal
        centered
        title='Diaspora Mortgage Facility'
        okButtonProps={modalButtonProps}
        open={isModal2Open}
        onOk={() => handleOk(2)}
        onCancel={() => handleCancel(2)}
      >
        <p className='mt-2 mb-5'>
          The organization has an MOU with ZNBS on a diaspora Mortgage Finance Product.
          <br />
          <br />
          The project operates under a generic model but will vary from one client need to the other due to different
          investor needs and payment plans.
          <br />
          <br />
          Diaspora Clients approach Sharesin or ZNBS for property investment discussions, Due diligence processes,
          property canvassing, property search, negotiations, conveyance, Terms and conditions.
        </p>

        <p className='text-[#B36824] mt-5'>+260 966805790 | admin@sharesinproperties.com</p>
      </Modal>

      <Modal
        centered
        title='Property Management'
        okButtonProps={modalButtonProps}
        open={isModal3Open}
        onOk={() => handleOk(3)}
        onCancel={() => handleCancel(3)}
      >
        <p>
          Recognizing that our business model involves investors from various countries, SharesIn Properties Limited
          boasts a team of seasoned professionals in property management. We have established comprehensive service
          level agreements with reputable service providers to guarantee the highest standards for both investors and
          property owners.
        </p>

        <div className='w-full px-5 lg:px-10 py-5 bg-[#B36824] my-5'>
          <form onSubmit={handleSubmit}>
            <p className='text-2xl font-semibold text-white capitalize py-2 border-b border-white'>Make an enquiry</p>

            <p className='text-white text-base font-light py-5 mb-5'>
              Save your time and easily rent or sell your property with the lowest commission on the real estate market.
            </p>

            <div className='mb-4'>
              <label htmlFor='name' className='text-xs text-white font-semibold'>
                Name
              </label>
              <input
                type='text'
                id='name'
                name='name'
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='email' className='text-xs text-white font-semibold'>
                Email
              </label>
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='message' className='text-xs text-white font-semibold'>
                Message
              </label>
              <textarea
                id='message'
                name='message'
                rows='4'
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            <button className='py-2 px-4 text-[#B36824] text-sm text-left rounded bg-white'>Submit</button>
          </form>
        </div>

        <p className='text-[#B36824] mt-5'>+260 966805790 | admin@sharesinproperties.com</p>
      </Modal>

      <div className='lg:h-[90vh] flex flex-col lg:flex-row gap-x-0 gap-y-20 justify-between items-center pt-20 lg:pt-0'>
        <div className='w-11/12 lg:w-5/12 mx-auto lg:mr-0 lg:ml-auto pr-10 h-fit'>
          <p className='lg:leading-tight lg:text-4xl text-2xl text-white font-bold capitalize'>
            Whether You Live in The Diaspora or A Local Property Investor
          </p>

          <p className='text-white font-light py-7'>
            SharesIn Properties Limited is Here to Make You Property Dreams Come True
          </p>

          <div className='flex lg:flex-row flex-wrap gap-x-5 gap-y-5 justify-left'>
            <button
              onClick={() => {
                window.location.href = "/houses"
              }}
              className='py-2 px-3 lg:py-3 lg:px-5 text-[#B36824] font-medium text-sm lg:text-sm text-left rounded bg-white'
            >
              Find My Dream Home
            </button>

            <button
              onClick={() => {
                handleOpen(1)
              }}
              className='py-2 px-3 lg:py-3 lg:px-5 text-[#B36824] font-medium text-sm lg:text-sm text-left rounded bg-white'
            >
              Sell My Property
            </button>

            <button
              onClick={() => {
                handleOpen(3)
              }}
              className='py-2 px-3 lg:py-3 lg:px-5 text-[#B36824] font-medium text-sm lg:text-sm text-left rounded bg-white'
            >
              Manage My Property
            </button>

            <button
              onClick={() => {
                handleOpen(2)
              }}
              className='py-2 px-3 lg:py-3 lg:px-5 text-[#B36824] font-medium text-sm lg:text-sm text-left rounded bg-white'
            >
              Diaspora Mortgage Facility
            </button>
          </div>
        </div>

        <img src={bannerImage} alt='bannerImage' className='w-full lg:w-6/12 h-full object-cover' />
      </div>
    </div>
  )
}

export default Banner
