import React from "react"
import aboutImage from "../../img/business.jpg"

const AboutUs = () => {
  return (
    <div className='w-11/12 lg:w-10/12 mx-auto py-20 pb-10 flex lg:flex-row flex-col gap-y-10 justify-between'>
      <div className='w-full lg:w-5/12'>
        <img src={aboutImage} alt='' />
      </div>

      <div className='w-full lg:w-6/12'>
        <p className='lg:text-4xl text-3xl font-bold leading-tight capitalize mb-5'>
          About <span className='text-[#B36824] leading-tight'>Us</span>
        </p>
        <hr className='h-[10px] w-[100px] bg-[#B36824] border border-[#B36824] mb-10' />
        <p className='text-gray-600 leading-relaxed'>
          Welcome to <b className="text-[#B36824]">SharesIn Properties Limited.</b> Our mission is to make your real estate journey seamless and
          enjoyable. With years of experience in the industry, we are committed to providing personalized service and
          finding solutions that meet your unique needs. 
        </p>
      </div>
    </div>
  )
}

export default AboutUs
