import React from "react"
import { Link } from "react-router-dom"

function EventListing({ event }) {
  const getFormattedDate = (dateString) => {
    const formattedDate = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return formattedDate.toLocaleDateString('en-US', options);
  }

  const getFormattedTime24Hour = (dateString) => {
    const formattedDate = new Date(dateString);
    const options = { hour: 'numeric', minute: 'numeric', hour12: false };
    return formattedDate.toLocaleTimeString('en-US', options);
  }

  return (
    <div className=''>
      <Link to={`/view_event/${event.id}`}>
        <img src={event.images[0].file} alt='house' className='h-64 w-full object-cover' />
        <div className='py-3'>
          <p className='text-xl text-black font-semibold capitalize line-clamp-1 pb-5'>
            {event.title}
          </p>

          <p className='text-base font-light line-clamp-2 mb-3'>
            {event.description}
          </p>

          <p className='text-base font-light'>
            <b>Date: </b>{getFormattedDate(event.eventDateTime)}
          </p>

          <p className='text-base font-light '>
          <b>Time: </b>{getFormattedTime24Hour(event.eventDateTime)}
          </p>
        </div>
      </Link>
    </div>
  )
}

export default EventListing
