import React from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { logOut } from '../firebase'

function AdminNavtab() {
  const navigate = useNavigate()

  const signOut = async () => {
    await logOut()
    navigate("/admin/login")
  }

  return (
    <div className='py-5 bg-[#B36824]'>
      <div className='w-11/12 lg:w-10/12 mx-auto text-white flex items-center justify-between'>
        <p className='text-xl font-bold'><Link to='/admin/home'>Admin Panel</Link></p>

        <ul className='hidden lg:flex items-center gap-x-10'>
          <li>
            <Link to='/admin/home'>Home</Link>
          </li>

          <li>
            <Link to='/admin/listing/index'>Houses</Link>
          </li>

          <li>
            <Link to='/admin/resource/index'>Blogs</Link>
          </li>

          <li>
            <Link to='/admin/newsletter/index'>Newsletter</Link>
          </li>

          <li>
            <Link to='/admin/event/index'>Events</Link>
          </li>

          <li>
            <button onClick={() => signOut()} className='bg-white text-[#B36824] font-semibold text-sm py-2 px-5 rounded'>Log out</button>
          </li>
        </ul>

        <button onClick={() => signOut()} className='lg:hidden bg-white text-[#B36824] font-semibold text-sm py-2 px-5 rounded'>Log out</button>
      </div>
    </div>
  )
}

export default AdminNavtab
