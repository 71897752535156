import React, { useState, useEffect } from "react"
import { uploadListing, authStatus } from "../../firebase"
import { useNavigate } from "react-router-dom"
import Loader from "../../components/loader"
import AdminNavtab from "../../components/adminNavtab"

const AddResource = () => {
  const navigate = useNavigate()
  const [Files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [resource, setResource] = useState({
    title: "",
    blogContent: "",
    images: [],
  })

  const checkStatus = async () => {
    try {
      let auth = await authStatus()
      auth ? console.log("logged") : navigate("/admin/login")
    } catch (err) {
      alert("error: unable to authenticate", err)
      navigate("/admin/login")
    }
  }

  useEffect(() => {
    checkStatus()
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setResource((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    setFiles([selectedFile])
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      let percentage = 0.0
      setLoading(true)

      await uploadListing(Files, resource, "blogs", (progress) => {
        percentage += parseInt(progress.toFixed(2), 10)
        console.log(percentage)
      })

      setLoading(false)
      navigate("/admin/resource/index")
    } catch (error) {
      setLoading(false)
      console.log("error: ", error)
    }
  }

  return (
    <div className=''>
      <AdminNavtab />
      
      {loading && <Loader />}

      <div className='w-11/12 lg:w-10/12 mx-auto my-10'>
        <p className='text-xl lg:text-3xl text-[#B36824] font-medium pb-10'>Add Blog Post</p>

        <form onSubmit={handleSubmit} className='px-3 py-5 border'>
          <div className='mb-4'>
            <label htmlFor='title' className='text-xs text-[#B36824]'>
              Title
            </label>
            <input
              type='text'
              id='title'
              name='title'
              className='mt-1 p-2 w-full border'
              value={resource.title}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='blogContent' className='text-xs text-[#B36824]'>
              Blog Content
            </label>
            <textarea
              id='blogContent'
              name='blogContent'
              className='mt-1 p-2 w-full border'
              value={resource.blogContent}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='banner' className='text-xs text-[#B36824]'>
              Banner (Image)
            </label>
            <input
              type='file'
              accept='image/*,'
              id='bannerFile'
              name='bannerFile'
              onChange={handleFileChange}
              className='mt-1 p-2 w-full border'
              required
            />
          </div>

          <div className='mt-4'>
            <button type='submit' className='bg-[#B36824] text-sm text-white py-2 px-5 rounded'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddResource
