import React from "react"
import Business from "../../img/business.jpg"
import { CheckSquareOutlined } from "@ant-design/icons"

function WhyUs() {
  return (
    <div className='bg-[#B36824]'>
      <div className='w-11/12 lg:w-10/12 mx-auto flex flex-col gap-y-10 lg:flex-row items-center justify-between py-20'>
        <img src={Business} alt='house' className='w-full lg:w-5/12 object-cover' />

        <div className='w-full lg:w-6/12'>
          <p className='lg:text-3xl text-2xl text-white font-bold leading-tight capitalize mb-5'>
            Why people trust <span className='text-white leading-tight'>Us?</span>
          </p>
          <hr className='h-[7px] w-[100px] bg-white border border-white mb-10' />

          <ul className='flex flex-col gap-y-5 text-base text-white text-sm font-light'>
            <li className='flex items-start gap-x-5'>
              <CheckSquareOutlined className='text-xl pt-1' />
              <p>
                <b className="font-semibold">Expert Guidance:</b> Benefit from our team of seasoned real estate professionals who provide expert
                guidance tailored to your unique needs.
              </p>
            </li>

            <li className='flex items-start gap-x-5'>
              <CheckSquareOutlined className='text-xl pt-1' />
              <p>
                <b className="font-semibold">Transparency Matters:</b> Our detailed and honest approach
                ensures you have all the information you need at every step of the process.
              </p>
            </li>

            <li className='flex items-start gap-x-5'>
              <CheckSquareOutlined className='text-xl pt-1' />
              <p>
                <b className="font-semibold">Client-Centric Approach:</b> We prioritize open communication,
                actively listening to your goals, and customizing our services to meet your expectations.
              </p>
            </li>

            <li className='flex items-start gap-x-5'>
              <CheckSquareOutlined className='text-xl pt-1' />
              <p>
                <b className="font-semibold">Proven Track Record:</b> With a solid track record of successful deals and satisfied clients,
                SharesIn Properties Limited has established itself as a trusted name in the real estate industry.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default WhyUs
