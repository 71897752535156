import React from "react"
import Carousel from "nuka-carousel"
import house from "../../img/house.jpg"

const defaultControlsConfig = {
  nextButtonStyle: {
    display: "block",
  },
  prevButtonStyle: {
    display: "block",
  },
  pagingDotsStyle: {
    border: "solid 1px #F97316",
    borderRadius: "5px",
    margin: "0 5px",
    display: "none"
  },
  prevButtonText: "<",
  nextButtonText: ">",
}

function ImgCarousel({ listing }) {
  return (
    <div className='w-full'>
      <Carousel
        dragging={true}
        autoplay={true}
        defaultControlsConfig={defaultControlsConfig}
        wrapAround={true}
        autoplayInterval={5000}
        speed={2000}
      >
        {listing.map((image) => (
          <img className='lg:w-8/12 mx-auto lg:h-[80vh] h-[50vh] object-cover' src={image.file} alt='house' />
        ))}
      </Carousel>
    </div>
  )
}

export default ImgCarousel
