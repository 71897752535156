import React, { useState, useEffect } from "react"
import ImgCarousel from "../components/viewHouse/imgCarousel"
import HouseDescription from "../components/viewHouse/houseDescription"
import Footer from "../components/footer"
import NavTab from "../components/navtab"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { getListing } from "../firebase"
import Loader from "../components/loader"

function ViewHouse() {
  const { id } = useParams()
  let listings = useSelector((state) => state.resources.listings)
  const [loading, setLoading] = useState(false)
  const [resourceData, setResourceData] = useState("")

  const getResources = async () => {
    setLoading(true)

    try {
      if (listings.length < 1) {
        listings = await getListing("houses")
      }
      const filteredListing = listings.filter((house) => house.id === id)
      setResourceData(...filteredListing)
      setLoading(false)
    } catch (error) {
      console.log("Error: ", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getResources()
  }, [])

  return (
    <div className='bg-gray-50'>
      <NavTab />

      {loading && <Loader />}

      {resourceData && (
        <>
          <ImgCarousel listing={resourceData.images} />
          <HouseDescription listing={resourceData} />
        </>
      )}

      <Footer />
    </div>
  )
}

export default ViewHouse
