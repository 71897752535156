import { createSlice } from "@reduxjs/toolkit"

export const resourceSlice = createSlice({
  name: "resources",
  initialState: {
    listings: [],
    blogs: [],
    events: []
  },
  reducers: {
    addResources: (state, action) => {
      state.listings = action.payload.listings
      state.blogs = action.payload.blogs
      state.events = action.payload.events
    },
    addHouses: (state, action) => {
      state.listings = action.payload
    },
    addBlogs: (state, action) => {
      state.blogs = action.payload
    },
    addEvents: (state, action) => {
      state.events = action.payload
    },
  },
})

export const { addResources, addHouses, addBlogs, addEvents } = resourceSlice.actions
export default resourceSlice.reducer
