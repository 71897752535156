import React, { useState } from "react"
import logo from "../img/logo1.png"
import { Link } from "react-router-dom"
import { AlignRightOutlined, CloseSquareOutlined } from "@ant-design/icons"

function Navbar({ textColor }) {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen)
  }

  const closeMobileMenu = () => {
    setMobileMenuOpen(false)
  }

  return (
    <div className='relative w-11/12 lg:w-10/12 mx-auto flex justify-between items-center py-5'>
      <Link to='/'>
        <div className='text-[white] flex items-center lg:text-xl'>
          <img src={logo} alt='logo' className='h-5 lg:h-5' />
          <p className='pl-1 text-[#B36824] font-bold'>SharesIn Properties Limited</p>
        </div>
      </Link>

      {/* Mobile Menu Icon */}
      <div className='lg:hidden cursor-pointer' onClick={toggleMobileMenu}>
        <AlignRightOutlined className='text-xl text-[#B36824]' />
      </div>

      {/* Overlay for Mobile Sidebar */}
      {isMobileMenuOpen && (
        <div
          className='lg:hidden fixed top-0 left-0 h-screen w-full bg-black opacity-50 z-40'
          onClick={closeMobileMenu}
        ></div>
      )}

      {/* Mobile Sidebar */}
      <div
        className={`lg:hidden fixed top-0 left-0 h-screen w-9/12 bg-white z-50 transition-transform duration-300 transform ${
          isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* Close Button */}
        <div className='absolute top-3 right-3 cursor-pointer py-2' onClick={closeMobileMenu}>
          <CloseSquareOutlined className='text-3xl text-[#B36824] ' />
        </div>

        <ul className='flex flex-col justify-center bg-amber-100 items-center gap-y-10 h-full text-[#B36824]  font-medium'>
          <li>
            <Link to='/' className='text-[#B36824]'>
              Home
            </Link>
          </li>
          <li>
            <Link to='/about' className='text-[#B36824]'>
              About
            </Link>
          </li>
          <li>
            <Link to='/houses' className='text-[#B36824]'>
              Properties
            </Link>
          </li>
          <li>
            <Link to='/resources' className='text-[#B36824]'>
              Resources
            </Link>
          </li>
          <li>
            <Link to='/csr' className='text-[#B36824]'>
              Social Responsibility
            </Link>
          </li>
        </ul>
      </div>

      {/* Regular Navigation Links */}
      <ul className='lg:flex gap-x-8 text-blue-950 text-sm font-medium hidden'>
        <li className='block text-[#B36824] hover:bg-gray-100 p-2'>
          <Link to='/'>Home</Link>
        </li>
        <li className='block text-[#B36824] hover:bg-gray-100 p-2'>
          <Link to='/about'>About</Link>
        </li>
        <li className='block text-[#B36824] hover:bg-gray-100 p-2'>
          <Link to='/houses'>Properties</Link>
        </li>
        <li className='block text-[#B36824] hover:bg-gray-100 p-2'>
          <Link to='/resources'>Resources</Link>
        </li>
        <li className='block text-[#B36824] hover:bg-gray-100 p-2'>
          <Link to='/csr'>Social Responsibility</Link>
        </li>
      </ul>
    </div>
  )
}

export default Navbar
