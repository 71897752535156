import React from "react"
import Navbar from "../components/navtab"
import Footer from "../components/footer"

function Termsandconditions() {
  return (
    <div className='bg-gray-50'>
      <div className='bg-white'>
        <Navbar />
      </div>

      <div className='w-11/12 lg:w-10/12 mx-auto py-10'>
        <div className='pb-20'>
          <p className='lg:text-4xl text-4xl font-bold leading-tight capitalize mb-5'>Terms And Conditions</p>
          <hr className='h-[10px] w-[250px] bg-[#B36824] border border-[#B36824] mb-20' />

          <p className='font-bold text-[#B36824] mb-5'>Acceptance of Terms</p>

          <p className='mb-10'>
            By subscribing to our newsletter service, you agree to comply with and be bound by the following terms and
            conditions. If you do not agree with any part of these terms, please do not subscribe to our newsletter.
          </p>

          <p className='font-bold text-[#B36824] mb-5'>Subscription Information</p>

          <p className='mb-10'>
            To subscribe to our newsletter, you will be required to provide a valid email address. You agree to provide
            accurate, current, and complete information during the subscription process and to update such information
            to keep it accurate, current, and complete.
          </p>

          <p className='font-bold text-[#B36824] mb-5'>Use of Information</p>

          <p className='mb-10'>
            We respect your privacy and will use your email address solely for the purpose of sending you our newsletter
            and related updates. We will not disclose, sell, or rent your personal information to any third party
            without your consent.
          </p>

          <p className='font-bold text-[#B36824] mb-5'>Frequency of Newsletters</p>

          <p className='mb-10'>
            Newsletters will be sent periodically, and the frequency may vary. We reserve the right to modify the
            frequency of newsletters at any time without prior notice.
          </p>

          <p className='font-bold text-[#B36824] mb-5'>Unsubscribe Options</p>

          <p className='mb-10'>
            You have the option to unsubscribe from our newsletter at any time. An "Unsubscribe" link is included in
            each newsletter email. Clicking on this link will remove your email address from our mailing list.
          </p>

          <p>
            For any questions or concerns regarding these terms and conditions, please contact us at
            <span className="font-bold"> admin@sharesinproperties.com</span>.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Termsandconditions
