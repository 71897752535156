import React from "react"
import NavTab from "../components/navtab"
import Footer from "../components/footer"

function CSR() {
  return (
    <div className='bg-gray-50'>
      <div className='bg-white'>
        <NavTab />
      </div>

      <div className='w-11/12 lg:w-10/12 mx-auto py-10'>
        <div className='pb-20'>
          <p className='lg:text-4xl text-3xl font-bold leading-tight capitalize mb-5'>“Komboni” Property Initiatives</p>
          <hr className='h-[10px] w-[150px] bg-[#B36824] border border-[#B36824] mb-10' />

          <p>
            SharesIn Properties Limited is committed to United Nations Sustainable Development Goal 11- Making
            Sustainable Cities and Communities by 2030. Specifically, SharesIn Properties Limited contributes to SDG
            11.1- Safe and Affordable Housing- ensuring safe and affordable housing and basic services and upgrading
            slums. This is also inline with African Union Agenda 2063 Priority Area on “Modern, Affordable and Liveable
            Habitats and Quality Basic Services”. We do this by working in Kombonis through our Komboni Initiatives
            Programme.
            <br />
            <br />
            A Komboni is a type of informal housing compound or shanty town common in urbanized areas of Zambia. An
            estimated 35% of Zambians live in urban areas and Kombonis exist in many of them. Kombonis typically have
            poor quality overcrowded housing, inadequate public services, limited access to water, poor sanitation, few
            healthcare facilities, and limited access to employment.
            <br />
            <br />
            Each year, SharesIn Properties Limited selects 2 or 3 Komboni Projects to work with. To support the Komboni
            Initiatives SharesIn Properties Limited gives a percentage of all its profits to the Komboni Initiatives. By
            buying a property through SharesIn Properties Limited- not only are you provided with professional service
            as you acquire your property but you are also contributing to bettering the lives of people living in the
            Komboni in Zambia.
            <br />
            <br />
            You can also donate directly to the Komboni Initiative. Every Ngwee goes a long way.
            <br />
            In 2024- SharesIn Properties Limited will be working with
          </p>
        </div>

        <div className='pb-20'>
          <p className='lg:text-2xl text-2xl font-bold leading-tight capitalize mb-5'>
            Project A - House Of Moses Kalingalinga
          </p>
          <hr className='h-[5px] w-[150px] bg-[#B36824] border border-[#B36824] mb-10' />

          <p>
            1. Provide essential supplies such as mealie meal, cooking oil, sugar, salt, etc.
            <br />
            2. Undertake painting activities for the living quarters to enhance the living environment for the children.
            <br />
            3. Offer real estate maintenance services to ensure the upkeep and well-being of the House of Moses
            property.
          </p>
        </div>

        <div className='pb-20'>
          <p className='lg:text-2xl text-2xl font-bold leading-tight capitalize mb-5'>Project B - Women In Need</p>
          <hr className='h-[5px] w-[150px] bg-[#B36824] border border-[#B36824] mb-10' />

          <p>
            1. Providing essential supplies to widows in selected high-density areas.
            <br />
            2. Extending assistance to underprivileged children to meet their basic needs.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default CSR
