import React from "react"
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, YoutubeOutlined } from "@ant-design/icons"
import logo from "../img/logo1.png"
import { Link } from "react-router-dom"

function Footer() {
  return (
    <div className='bg-[#B36824]'>
      <div className='w-10/12 mx-auto flex flex-col lg:flex-row gap-x-32 gap-y-20 pt-20 pb-20'>
        <div className='w-full lg:w-5/12 flex flex-col '>
        <div className='text-[white] flex lg:text-xl mb-5'>
          <div className="bg-white py-1">
          <img src={logo} alt='logo' className='h-5 lg:h-5' />
          </div>

        <p className="pl-1 text-white font-bold">SharesIn Properties Limited</p>
      </div>

          <div className=''>
            <p className='font-Lato text-sm text-white font-light'>
              <b className="font-semibold">SharesIn Properties Limited</b> provides you with a lists of property opportunities in different countries.
              Investors can select properties of their choice knowing that their investment is real and safe
            </p>
          </div>
        </div>

        <div className="w-full lg:w-8/12 flex lg:flex-row flex-col gap-y-10 justify-between ">
          <div className='w-full lg:w-3/12 flex flex-col gap-y-5'>
            <div className='flex items-end'>
              <p className='font-Lato text-base text-white font-bold'>Services</p>
            </div>

            <div className=''>
              <ul className='font-Lato font-light text-white text-sm flex flex-col gap-y-3'>
                <li>Buy</li>

                <li>Sell</li>

                <li>Consult</li>
              </ul>
            </div>
          </div>

          <div className='w-full lg:w-3/12 flex flex-col gap-y-5'>
            <div className='flex items-end'>
              <p className='font-Lato text-base text-white font-bold'>Links</p>
            </div>

            <div className=''>
              <ul className='font-Lato font-light text-white text-sm flex flex-col gap-y-3'>
                <li>
                  <a href='/about'>About</a>
                </li>

                <li>
                  <a href='/resources'>Resources</a>
                </li>

                <li>
                  <a href='/houses'>Houses</a>
                </li>

                <li>
                  <a href='/termsandconditions'>Terms And Conditions</a>
                </li>
              </ul>
            </div>
          </div>

          <div className='w-full lg:w-4/12 flex flex-col gap-y-5'>
            <div className='flex items-end'>
              <p className='font-Lato text-base text-white font-bold'>Contact us</p>
            </div>

            <div className=''>
              <ul className='font-Lato font-light text-white text-sm flex flex-col gap-y-3'>
                <li>Foxdale Court, Zambezi Road, Lusaka.</li>

                <li>+260 966805790</li>

                <li>admin@sharesinproperties.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='w-10/12 mx-auto flex flex-col lg:flex-row items-top justify-between pt-2 pb-5 gap-y-5 border-t -gray-700'>
        <p className='font-Lato text-sm text-white font-light'>&copy; 2023 SharesIn Properties Limited</p>

        <ul className='font-Lato text-sm text-white font-normal flex flex-col lg:flex-row gap-x-10 gap-y-2'>
          <li>
            <a href='https://www.facebook.com/profile.php?id=100092305284559' className='flex items-center gap-x-2'>
              <FacebookOutlined /> Facebook
            </a>
          </li>

          <li>
            <a href='https://www.instagram.com/sharesin_properties/' className='flex items-center gap-x-2'>
              <InstagramOutlined /> Instagram
            </a>
          </li>

          <li>
            <a href='https://www.linkedin.com/company/sharesin-properties/' className='flex items-center gap-x-2'>
              <LinkedinOutlined /> LinkedIn
            </a>
          </li>

          {/* <li>
            <a href='https://facebook.com' className='flex items-center gap-x-2'>
              <YoutubeOutlined /> YouTube
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default Footer
