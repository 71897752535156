import React, { useState, useEffect } from "react"
import NavTab from "../components/navtab"
import Footer from "../components/footer"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { getListing } from "../firebase"
import Loader from "../components/loader"

function ViewEventHome() {
  const { id } = useParams()
  let listings = useSelector((state) => state.resources.events)
  const [loading, setLoading] = useState(false)
  const [resourceData, setResourceData] = useState("")

  const getResources = async () => {
    setLoading(true)

    try {
      if (listings.length < 1) {
        listings = await getListing("events")
      }
      const filteredListing = listings.filter((blog) => blog.id === id)
      setResourceData(...filteredListing)
      console.log(filteredListing)
      setLoading(false)
    } catch (error) {
      console.log("Error: ", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getResources()
  }, [])

  const getFormattedDate = (dateString) => {
    const formattedDate = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return formattedDate.toLocaleDateString('en-US', options);
  }

  const getFormattedTime24Hour = (dateString) => {
    const formattedDate = new Date(dateString);
    const options = { hour: 'numeric', minute: 'numeric', hour12: false };
    return formattedDate.toLocaleTimeString('en-US', options);
  }


  return (
    <div className='bg-gray-50'>
      <NavTab />

      {loading && <Loader />}

      {resourceData && (
        <div className='w-11/12 lg:w-10/12 mx-auto py-10'>
          <div className='flex h-96 py-10'>
            <img src={resourceData.images[0].file} alt='house' className='mx-auto object-cover rounded-md' />
          </div>

          <div className='py-10'>
            <div className='pt-2'>
              <p className='text-2xl font-semibold py-3'>{resourceData.title}</p>

              <p className='text-base font-light mb-5'>
                {resourceData.description}
              </p>

              <p className='text-base font-light'>
              <b>Date: </b>{getFormattedDate(resourceData.eventDateTime)}
              </p>

              <p className='text-base font-light'>
              <b>Time: </b>{getFormattedTime24Hour(resourceData.eventDateTime)}
              </p>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  )
}

export default ViewEventHome
