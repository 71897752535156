import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { addBlogs } from "../redux/resourceSlice"
import { getListing } from "../firebase"
import BlogListing from "../components/home/blogListing"
import NavTab from "../components/navtab"
import Footer from "../components/footer"
import Loader from "../components/loader"

function Blogs() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [resourceData, setResourceData] = useState("")


    // Get Lisiting Data
    const getResources = async () => {
      setLoading(true)
  
      try {
        const blogData = await getListing("blogs")
        setResourceData(blogData)
        dispatch(addBlogs(blogData))
        setLoading(false)
      } catch (error) {
        console.log("Error: ", error)
        setLoading(false)
      }
    }
  
    useEffect(() => {
      getResources()
    }, [])

  return (
    <div className='bg-gray-50'>
      <div className='bg-white'>
        <NavTab />
      </div>

      <div className='w-11/12 lg:w-10/12 mx-auto py-10'>
        <p className='lg:text-4xl text-4xl font-bold leading-tight capitalize py-10'>
          Explore our <span className='text-[#B36824] leading-tight'>Blog</span>
        </p>
        <hr className='h-[10px] w-[200px] bg-[#B36824] border border-[#B36824] mb-10' />

        {loading ? (
          <Loader />
        ) : resourceData.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
            {resourceData.map((blog, index) => (
              <div className=''>
                <BlogListing key={index} blog={blog} />
              </div>
            ))}
          </div>
        ) : (
          <div className='h-[50vh]'>
            <p>No resources currently available</p>
          </div>
        )}
      </div>

      <Footer />
    </div>
  )
}

export default Blogs
