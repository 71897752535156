import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./pages/home"
import ViewHouse from "./pages/viewHouse"
import Blogs from "./pages/blogs"
import Houses from "./pages/houses"
import Events from "./pages/events"
import About from "./pages/about"
import ViewBlog from "./pages/viewBlog"
import ListingIndex from "./pages/admin/ListingIndex"
import AddListing from "./pages/admin/addListing"
import ViewListing from "./pages/admin/viewListing"
import AddResource from "./pages/admin/addResource"
import ResourceIndex from "./pages/admin/resourceIndex"
import ViewResource from "./pages/admin/viewResource"
import AdminHome from "./pages/admin/adminHome"
import Login from "./pages/admin/login"
import NewsletterIndex from "./pages/admin/newsletterIndex"
import SendNewsletter from "./pages/admin/sendNewsletter"
import Termsandconditions from "./pages/termsandconditions"
import Unsubscribe from "./pages/unsubcribe"
import CSR from "./pages/csr"
import AddEvent from "./pages/admin/addEvent"
import ViewEvent from "./pages/admin/viewEvent"
import EventIndex from "./pages/admin/eventIndex"
import ViewEventHome from "./pages/viewEvent"
import EditResource from "./pages/admin/editResource"
import EditListing from "./pages/admin/editListing"
import EditEvent from "./pages/admin/editEvent"
import tools from '../src/img/tools.jpg'


function App() {
  return (
    <div className="font-Poppins">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/view_house/:id" element={<ViewHouse />} />
          <Route path="/resources" element={<Blogs />} />
          <Route path="/houses" element={<Houses />} />
          <Route path="/events" element={<Events />} />
          <Route path="/about" element={<About />} />
          <Route path="/csr" element={<CSR />} />
          <Route path="/termsandconditions" element={<Termsandconditions />} />
          <Route path="/view_resource/:id" element={<ViewBlog />} />
          <Route path="/view_event/:id" element={<ViewEventHome />} />
          <Route path="/unsubscribe/:id" element={<Unsubscribe />} />

          <Route path="/admin/home" element={<AdminHome />} />
          <Route path="/admin/login" element={<Login />} />

          <Route path="/admin/listing/add" element={<AddListing />} />
          <Route path="/admin/listing/index" element={<ListingIndex />} />
          <Route path="/admin/listing/:id" element={<ViewListing />} />
          <Route path="/admin/listing/edit/:id" element={<EditListing />} />

          <Route path="/admin/resource/add" element={<AddResource />} />
          <Route path="/admin/resource/index" element={<ResourceIndex />} />
          <Route path="/admin/resource/:id" element={<ViewResource />} />
          <Route path="/admin/resource/edit/:id" element={<EditResource />} />

          <Route path="/admin/event/add" element={<AddEvent />} />
          <Route path="/admin/event/index" element={<EventIndex />} />
          <Route path="/admin/event/:id" element={<ViewEvent />} />
          <Route path="/admin/event/edit/:id" element={<EditEvent />} />

          <Route path="/admin/newsletter/index" element={<NewsletterIndex />} />
          <Route path="/admin/newsletter/send" element={<SendNewsletter />} />
        </Routes>
      </BrowserRouter>

    </div>
  )
}

export default App
