import React from "react"
import Find from "../../img/search.jpg"
import Documents from "../../img/documents.jpg"
import Keys from "../../img/keys.jpg"

function HowItWorks() {
  return (
    <div className='w-11/12 lg:w-10/12 mx-auto py-20'>
      <div className='w-full flex flex-col gap-y-32 py-10'>
        <div className='w-full lg:w-full flex lg:flex-row flex-col gap-y-10 justify-between'>
          <div className='lg:w-5/12'>
            <p className='lg:text-4xl text-3xl font-bold leading-tight capitalize mb-5'>
              1. Find The <span className='text-[#B36824] leading-tight'>Property</span>
            </p>
            <hr className='h-[10px] w-[150px] bg-[#B36824] border border-[#B36824] mb-10' />

            <p className='text-base font-light mb-10'>
              Explore a diverse portfolio of properties with SharesIn Properties Limited. Our user-friendly platform
              allows you to browse, filter, and find the ideal real estate that aligns with your preferences and
              investment goals.
            </p>

            <button
              onClick={() => {
                window.location.href = "/houses"
              }}
              className='py-3 px-5 text-white font-medium text-sm text-left rounded bg-[#B36824]'
            >
              View Listings
            </button>
          </div>

          <img src={Find} alt='search' className='lg:w-5/12 lg:h-[350px] h-[250px] object-cover' />
        </div>

        <div className='w-full lg:w-full flex lg:flex-row flex-col gap-y-10 justify-between'>
          <img src={Documents} alt='search' className='lg:w-5/12 lg:h-[350px] h-[250px] object-cover' />

          <div className='lg:w-5/12'>
            <p className='lg:text-4xl text-3xl font-bold leading-tight capitalize mb-5'>
              2. Paper <span className='text-[#B36824] leading-tight'>Work</span>
            </p>
            <hr className='h-[10px] w-[150px] mr-auto bg-[#B36824] border border-[#B36824] mb-10' />

            <p className='text-base font-light mb-10'>
              Streamlined document processing makes your journey seamless. Our team assists you in navigating the
              necessary paperwork, ensuring a transparent and efficient transaction. From contracts to legal
              requirements, we've got you covered.
            </p>

            <button
              onClick={() => {
                window.location.href = "/resources"
              }}
              className='py-3 px-5 text-white font-medium text-sm text-left rounded bg-[#B36824]'
            >
              Visit Our Blog
            </button>
          </div>
        </div>

        <div className='w-full lg:w-full flex lg:flex-row flex-col gap-y-10 justify-between'>
          <div className='lg:w-5/12'>
            <p className='lg:text-4xl text-3xl font-bold leading-tight capitalize mb-5'>
              3. Own <span className='text-[#B36824] leading-tight'>Property</span>
            </p>
            <hr className='h-[10px] w-[150px] bg-[#B36824] border border-[#B36824] mb-10' />

            <p className='text-base font-light mb-10'>
              Once all details are finalized, it's time to take possession. We facilitate a smooth handover, ensuring
              you receive the keys to your new property hassle-free. Your satisfaction and excitement are our top
              priorities during this pivotal moment.
            </p>

            {/* <button className='py-3 px-5 text-white font-medium text-sm text-left rounded bg-[#B36824]'>
              What Our Clients Say
            </button> */}
          </div>

          <img src={Keys} alt='search' className='lg:w-5/12 lg:h-[350px] h-[250px] object-cover' />
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
