import React from "react"
import house from "../../img/house.jpg"
import { Link } from "react-router-dom"

function BlogListing({ blog }) {
  return (
    <div className=''>
      <Link to={`/view_resource/${blog.id}`}>
        <img src={blog.images[0].file} alt='house' className='h-64 w-full object-cover' />
        <div className='py-3'>
          <p className='text-xl text-black font-semibold capitalize line-clamp-1 pb-5'>
            {blog.title}
          </p>

          <p className='text-base font-light line-clamp-2 '>
            {blog.blogContent}
          </p>
        </div>
      </Link>
    </div>
  )
}

export default BlogListing
