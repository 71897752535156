import React, { useEffect } from "react"
import AdminNavtab from "../../components/adminNavtab"
import { authStatus } from "../../firebase"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

function AdminHome() {
  const navigate = useNavigate()

  const checkStatus = async () => {
    try {
      let auth = await authStatus()
      auth ? console.log("logged") : navigate("/admin/login")
    } catch (err) {
      alert("error: unable to authenticate", err)
      navigate("/admin/login")
    }
  }

  useEffect(() => {
    checkStatus()
  }, [])
  return (
    <div>
      <AdminNavtab />

      <div className='w-11/12 lg:w-10/12 mx-auto py-20 lg:h-[80vh] flex flex-col lg:flex-row gap-y-10 items-center lg:justify-between'>
        <Link to='/admin/listing/index' className="w-[100%] lg:w-fit">
          <div className='text-xl text-[#B36824] text-center py-10 px-2 lg:p-20 font-normal bg-amber-50 border border-[#B36824]'>Houses</div>
        </Link>

        <Link to='/admin/resource/index' className="w-[100%] lg:w-fit">
          <div className='text-xl text-[#B36824] text-center py-10 px-2 lg:p-20 font-normal bg-amber-50 border border-[#B36824]'>Blogs</div>
        </Link>

        <Link to='/admin/newsletter/index' className="w-[100%] lg:w-fit">
          <div className='text-xl text-[#B36824] text-center py-10 px-2 lg:p-20 font-normal bg-amber-50 border border-[#B36824]'>
            Newsletter
          </div>
        </Link>

        <Link to='/admin/event/index' className="w-[100%] lg:w-fit">
          <div className='text-xl text-[#B36824] text-center py-10 px-2 lg:p-20 font-normal bg-amber-50 border border-[#B36824]'>
            Events
          </div>
        </Link>
      </div>
    </div>
  )
}

export default AdminHome
