import React from "react"
import BlogListing from "./blogListing"

function BlogSection({ blogResources }) {
  return (
    <div className='py-20'>
      <div className='w-11/12 lg:w-10/12 mx-auto'>
        <p className='lg:text-4xl text-3xl font-bold leading-tight capitalize mb-5'>
          Explore our <span className='text-[#B36824] leading-tight'>Blog</span>
        </p>
        <hr className='h-[10px] w-[200px] bg-[#B36824] border border-[#B36824] mb-10' />

        <div className='flex flex-col lg:flex-row gap-10 py-10'>
          {blogResources.slice(0, 3).map((blog, index) => (
            <div className='lg:w-4/12'>
              <BlogListing key={index} blog={blog} />
            </div>
          ))}
        </div>

        <div className='flex justify-center'>
          <button
            onClick={() => {
              window.location.href = "/resources"
            }}
            className='py-2 px-4 text-white text-sm text-left rounded bg-[#B36824]'
          >
            See All Blogs
          </button>
        </div>
      </div>
    </div>
  )
}

export default BlogSection
