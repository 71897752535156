import React, { useState } from "react"
import { getSubcribers } from "../../firebase"
import { useNavigate } from "react-router-dom"
import Loader from "../../components/loader"
import AdminNavtab from "../../components/adminNavtab"
import emailjs from "@emailjs/browser"

function SendNewsletter() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [newsletter, setnewsletter] = useState({
    title: "",
    message: "",
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setnewsletter((prevData) => ({ ...prevData, [name]: value }))
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      let subcribers = await getSubcribers("newsletter")
      let templateParams = {}

      for (const subcriber of subcribers) {
        templateParams = {
          title: newsletter.title,
          message: newsletter.message,
          id: subcriber.id,
          email: subcriber.email,
        }

        await emailjs.send("service_4mxc341", "template_npiqmee", templateParams, "GOt38AnMGYeGsBpE-")
      }

      setLoading(false)
      navigate("/admin/newsletter/index")
    } catch (error) {
      console.log("error: ", error)
    }
  }

  return (
    <div>
      <AdminNavtab />

      {loading && <Loader />}

      <div className='w-11/12 lg:w-10/12 mx-auto my-10'>
        <p className='text-xl lg:text-3xl text-[#B36824] font-medium pb-10'>Send Newsletter</p>

        <form onSubmit={handleSubmit} className='px-3 py-5 border'>
          <div className='mb-4'>
            <label htmlFor='title' className='text-xs text-[#B36824]'>
              Title
            </label>
            <input
              type='text'
              id='title'
              name='title'
              value={newsletter.title}
              onChange={handleInputChange}
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              required
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='message' className='text-xs text-[#B36824]'>
              Message
            </label>
            <textarea
              id='message'
              name='message'
              value={newsletter.message}
              onChange={handleInputChange}
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              rows={10}
              required
            />
          </div>

          <div className='mt-4'>
            <button type='submit' className='bg-[#B36824] text-sm text-white py-2 px-5 rounded'>
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SendNewsletter
