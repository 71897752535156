import React, { useState } from "react"
import { MailFilled, LoadingOutlined, PhoneFilled, PushpinFilled } from "@ant-design/icons"
import emailjs from "@emailjs/browser"
import { Spin } from "antd"

function ContactUs() {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)

      let templateParams = {
        title: formData.name,
        message: `Name: ${formData.name}\n Message: ${formData.message}\n Email: ${formData.email}`,
        id: formData.email,
        email: "admin@sharesinproperties.com",
      }

      await emailjs.send("service_4mxc341", "template_npiqmee", templateParams, "GOt38AnMGYeGsBpE-")

      setFormData({
        name: "",
        email: "",
        message: "",
      })

      setLoading(false)

      alert("Success! Form successfully submitted.")

      //navigate("/admin/newsletter/index")
    } catch (error) {
      console.log("error: ", error, formData)
    }
  }

  return (
    <div className='py-32'>
      <div className='w-11/12 lg:w-10/12 mx-auto flex flex-col gap-y-20 lg:flex-row justify-between'>
        <div className='w-full lg:w-5/12'>
          <p className='lg:text-4xl text-4xl font-bold leading-tight capitalize mb-5'>
            A new <span className='text-[#B36824] leading-tight'>way of living</span>
          </p>
          <hr className='h-[10px] w-[200px] bg-[#B36824] border border-[#B36824] mb-10' />

          <p className='text-base font-light'>
            Ready to transform your living experience? Contact us today through the form and embark on a journey to
            discover a new way of living. Whether you're seeking your dream home or exploring investment opportunities,
            SharesIn Properties Limited is here to make your vision a reality.
          </p>

          <div className='py-10 flex flex-col gap-y-3'>
            <p href='https://facebook.com' className='flex font-light items-center gap-x-2'>
              <PushpinFilled className='text-[#B36824]' /> Foxdale Court, Zambezi Road, Lusaka.
            </p>

            <p href='https://facebook.com' className='flex font-light items-center gap-x-2'>
              <PhoneFilled className='text-[#B36824]' /> +260 966805790
            </p>

            <p href='https://facebook.com' className='flex font-light items-center gap-x-2'>
              <MailFilled className='text-[#B36824]' /> admin@sharesinproperties.com
            </p>
          </div>
        </div>

        <div className='w-full lg:w-6/12 px-5 lg:px-10 py-10 bg-[#B36824]'>
          <form onSubmit={handleSubmit}>
            <p className='text-2xl font-semibold text-white capitalize py-2 border-b border-white'>Make an enquiry</p>

            <p className='text-white text-base font-light py-5 mb-5'>
              Save your time and easily rent or sell your property with the lowest commission on the real estate market.
            </p>

            <div className='mb-4'>
              <label htmlFor='name' className='text-xs text-white font-semibold'>
                Name
              </label>
              <input
                type='text'
                id='name'
                name='name'
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='email' className='text-xs text-white font-semibold'>
                Email
              </label>
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='message' className='text-xs text-white font-semibold'>
                Message
              </label>
              <textarea
                id='message'
                name='message'
                rows='4'
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
            <br />

            <button className='py-2 px-4 text-[#B36824] text-sm text-left rounded bg-white'>Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
