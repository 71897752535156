import React, { useState, useEffect } from "react"
import { updateResource, authStatus } from "../../firebase"
import { useLocation, useNavigate } from "react-router-dom"
import Loader from "../../components/loader"
import AdminNavtab from "../../components/adminNavtab"
import { CloseCircleTwoTone } from "@ant-design/icons"

const EditListing = () => {
  let { state } = useLocation()
  const navigate = useNavigate()
  const [Files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [houseListing, setHouseListing] = useState(state)

  const checkStatus = async () => {
    try {
      let auth = await authStatus()
      auth ? console.log("logged") : navigate("/admin/login")
    } catch (err) {
      alert("error: unable to authenticate", err)
      navigate("/admin/login")
    }
  }

  useEffect(() => {
    checkStatus()
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setHouseListing((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleDeleteItem = (name) => {
    const updatedObject = { ...houseListing }
    const itemIndex = updatedObject.images.findIndex((item) => item.name === name)

    if (itemIndex !== -1) {
      updatedObject.images.splice(itemIndex, 1)
    }
    setHouseListing(updatedObject)
  }

  // Handle array input changes
  const handleArrayInputChange = (e, arrayName) => {
    const { value } = e.target
    setHouseListing((prevData) => ({
      ...prevData,
      [arrayName]: value.split(","),
    }))
  }

  // Handle file input changes
  const handleFileInputChange = (e) => {
    const files = e.target.files
    setFiles([...files])
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      let percentage = 0.0
      setLoading(true)
      await updateResource(Files, houseListing, "houses", (progress) => {
        percentage += parseInt(progress.toFixed(2), 10)
        console.log(percentage / Files.length)
      })

      setLoading(false)
      navigate("/admin/listing/index")
    } catch (error) {
      setLoading(false)
      console.log("error: ", error)
    }
  }

  return (
    <div>
      <AdminNavtab />

      {loading && <Loader />}

      <div className='w-11/12 lg:w-10/12 mx-auto my-10'>
        <p className='text-xl lg:text-3xl text-[#B36824] font-medium pb-10'>Edit House Listing</p>

        <form onSubmit={handleSubmit} className='px-3 py-5 border'>
          <div className='mb-4'>
            <label htmlFor='houseName' className='text-xs text-[#B36824]'>
              House Name
            </label>
            <input
              type='text'
              id='houseName'
              name='houseName'
              value={houseListing.houseName}
              onChange={handleInputChange}
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              required
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='houseDescription' className='text-xs text-[#B36824]'>
              House Description
            </label>
            <textarea
              id='houseDescription'
              name='houseDescription'
              value={houseListing.houseDescription}
              onChange={handleInputChange}
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              required
            ></textarea>
          </div>

          <div className='mb-4'>
            <label htmlFor='location' className='text-xs text-[#B36824]'>
              Location
            </label>
            <input
              type='text'
              id='location'
              name='location'
              value={houseListing.location}
              onChange={handleInputChange}
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              required
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='price' className='text-xs text-[#B36824]'>
              Price
            </label>
            <input
              type='text'
              id='price'
              name='price'
              value={houseListing.price}
              onChange={handleInputChange}
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              required
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='locationPin' className='text-xs text-[#B36824]'>
              Location Pin (Google Maps Link)
            </label>
            <input
              type='text'
              id='locationPin'
              name='locationPin'
              value={houseListing.locationPin}
              onChange={handleInputChange}
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              required
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='propertyFeatures' className='text-xs text-[#B36824]'>
              Property Features (comma-separated)
            </label>
            <input
              type='text'
              id='propertyFeatures'
              name='propertyFeatures'
              value={houseListing.propertyFeatures.join(",")}
              onChange={(e) => handleArrayInputChange(e, "propertyFeatures")}
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              required
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='nearbyAttractions' className='text-xs text-[#B36824]'>
              Nearby Attractions (comma-separated)
            </label>
            <input
              type='text'
              id='nearbyAttractions'
              name='nearbyAttractions'
              value={houseListing.nearbyAttractions.join(",")}
              onChange={(e) => handleArrayInputChange(e, "nearbyAttractions")}
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              required
            />
          </div>

          <div className='mb-4'>
            <label htmlFor='houseImages' className='text-xs text-[#B36824]'>
              House Images
            </label>

            {houseListing.images && (
              <>
                {houseListing.images.map((file, index) => (
                  <div className='w-fit relative border rounded bg-primary px-5 py-3 flex-row' id={index}>
                    <p className=''>
                      <a className='text-secondary underline' href={file.file}>
                        {file.name}
                      </a>
                    </p>

                    <CloseCircleTwoTone
                      onClick={() => handleDeleteItem(file.name)}
                      className='absolute -top-1 -right-1'
                    />
                  </div>
                ))}{" "}
              </>
            )}

            <input
              type='file'
              id='houseImages'
              name='houseImages'
              onChange={handleFileInputChange}
              multiple
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              accept='image/*'
            />
          </div>

          <div className="mb-4 w-fit">
            <label htmlFor="sold" className="text-xs text-[#B36824]">
              Sold
            </label>
            <select
              id="sold"
              name="sold"
              value={houseListing.sold}
              onChange={handleInputChange}
              className="form-select mt-1 text-sm px-1 py-2 block w-full border"
              required
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>

          {/* <div className='mb-4'>
            <label htmlFor='houseVideo' className='text-xs text-[#B36824]'>
              House Video
            </label>
            <input
              type='text'
              id='houseVideo'
              name='houseVideo'
              value={houseListing.houseVideo}
              onChange={handleInputChange}
              className='form-input mt-1 text-sm px-1 py-2 block w-full border'
              required
            />
          </div> */}

          <div className='mt-4'>
            <button type='submit' className='bg-[#B36824] text-sm text-white py-2 px-5 rounded'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditListing
