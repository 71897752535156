import React, { useEffect } from "react"
import { authStatus } from "../../firebase"
import { useLocation, useNavigate } from "react-router-dom"
import AdminNavtab from "../../components/adminNavtab"

const ViewEvent = () => {
  let { state } = useLocation()
  const navigate = useNavigate()

  const checkStatus = async () => {
    try {
      let auth = await authStatus()
      auth ? console.log("logged") : navigate("/admin/login")
    } catch (err) {
      alert("error: unable to authenticate", err)
      navigate("/admin/login")
    }
  }

  const getFormattedDate = (dateString) => {
    const formattedDate = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return formattedDate.toLocaleDateString('en-US', options);
  }

  const getFormattedTime24Hour = (dateString) => {
    const formattedDate = new Date(dateString);
    const options = { hour: 'numeric', minute: 'numeric', hour12: false };
    return formattedDate.toLocaleTimeString('en-US', options);
  }

  useEffect(() => {
    checkStatus()
  }, [])

  return (
    <div>
      <AdminNavtab />

      <div className='w-11/12 lg:w-10/12 mx-auto my-10 border'>
        <p className='text-md font-medium text-white bg-[#B36824] amber-500 px-3 py-5'>View Blog Resouce</p>

        <p className='px-3 py-2 text-sm'>
          <span className='font-semibold'>Title: </span>
          {state.title}
        </p>

        <p className='px-3 py-2 text-sm'>
          <span className='font-semibold'>Description: </span>
          {state.description}
        </p>

        <p className='px-3 py-2 text-sm'>
          <span className='font-semibold'>Date: </span>
          {getFormattedDate(state.eventDateTime)}
        </p>

        <p className='px-3 py-2 text-sm'>
          <span className='font-semibold'>Time: </span>
          {getFormattedTime24Hour(state.eventDateTime)}
        </p>

        <div className='px-3 py-2 text-sm'>
          <p className='font-semibold'>Images:</p>

          <div className='flex flex-wrap gap-5 py-5'>
            {state.images.map((image, index) => (
              <img src={image.file} alt='houseImage' className='w-full lg:w-3/12 object-cover' />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewEvent
