import React, { useState } from "react"
import { Button } from "antd"
import { PushpinTwoTone } from "@ant-design/icons"
import MortgageCalculator from "./morgageCalculator"
import { WhatsAppOutlined, MailOutlined, PhoneOutlined, LoadingOutlined, PushpinOutlined } from "@ant-design/icons"
import { list } from "firebase/storage"
import emailjs from "@emailjs/browser"
import { Spin } from "antd"

function HouseDescription({ listing }) {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)

      let templateParams = {
        title: formData.name,
        message: `Name: ${formData.name}\n Message: ${formData.message}\n Email: ${formData.email}`,
        id: formData.email,
        email: "admin@sharesinproperties.com",
      }

      await emailjs.send("service_4mxc341", "template_npiqmee", templateParams, "GOt38AnMGYeGsBpE-")

      setFormData({
        name: "",
        email: "",
        message: "",
      })

      setLoading(false)

      alert("Success! Form successfully submitted.")

      //navigate("/admin/newsletter/index")
    } catch (error) {
      console.log("error: ", error, formData)
    }
  }

  const openInWhatsApp = () => {
    const whatsappUrl = `https://wa.me/260966805790`
    window.open(whatsappUrl, "_blank")
  }

  const openInGoogleMaps = (mapsUrl) => {
    window.open(mapsUrl, "_blank")
  }

  return (
    <div className='lg:w-10/12 w-11/12 mx-auto py-10'>
      <div className='py-5 border-b'>
        <p className='lg:text-3xl text-2xl text-[#B36824] font-semibold pb-3'>{listing.houseName}</p>

        <div className='flex items-center'>
          <p className='text-base flex items-center gap-2 pr-5'>
            <PushpinTwoTone twoToneColor='red' />
            {listing.location}
          </p>

          <p className='text-base border-l pl-5'>{listing.price}</p>
        </div>
      </div>

      <div className='py-10 border-b'>
        <p className='lg:text-2xl text-xl text-[#B36824] font-semibold pb-5'>Description</p>

        <p className='text-base font-light'>{listing.houseDescription}</p>
      </div>

      <div className='py-10 border-b'>
        <p className='lg:text-2xl text-xl text-[#B36824] font-semibold pb-5'>Location</p>

        <div className='flex flex-col lg:flex-row gap-y-10'>
          <p className='lg:w-3/5 text-base font-light'>{listing.location}</p>

          <Button
            type='primary'
            onClick={() => openInGoogleMaps(listing.locationPin)}
            size={"large"}
            icon={<PushpinOutlined />}
            className='mx-auto font-Lato text-base font-light bg-[#B36824]'
          >
            Show on map
          </Button>
        </div>
      </div>

      <div className='py-10 border-b'>
        <p className='lg:text-2xl text-xl text-[#B36824] font-semibold pb-5'>Property Features</p>

        <div className='flex flex-wrap gap-y-3 text-sm font-light'>
          {listing.propertyFeatures.map((feature) => (
            <p className='w-full lg:w-6/12'>&#x2022; {feature}</p>
          ))}
        </div>
      </div>

      <div className='py-10 border-b'>
        <p className='lg:text-2xl text-xl text-[#B36824]  text-[#B36824] font-semibold pb-5'>What's Nearby?</p>

        <div className='flex flex-wrap gap-y-3 text-sm font-light'>
          {listing.nearbyAttractions.map((attraction) => (
            <p className='w-full lg:w-6/12'>&#x2022; {attraction}</p>
          ))}
        </div>
      </div>

      {/* <div className="py-10 border-b">
        <p className="lg:text-2xl text-xl text-[#B36824] font-semibold pb-5">Video Tour</p>

        <iframe
          max-width="640"
          max-height="390"
          src="https://www.youtube.com/embed/VEU56oUA3VQ"
          title="YouTube Video Player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div> */}

      <div className='py-10 border-b'>
        <p className='lg:text-2xl text-xl text-[#B36824] font-semibold pb-5'>Mortgage Calculator</p>

        <MortgageCalculator />
      </div>

      <div className='py-10'>
        <p className='lg:text-2xl text-xl text-[#B36824] font-semibold pb-5'>Interested? Call or send us a message</p>

        <div className='w-full px-5 lg:px-10 py-5 bg-[#B36824] my-5'>
          <form onSubmit={handleSubmit}>
            <p className='text-2xl font-semibold text-white capitalize py-2 border-b border-white mb-5'>
              Make an enquiry
            </p>

            {/* <p className='text-white text-base font-light py-5 mb-5'>
              Save your time and easily rent or sell your property with the lowest commission on the real estate market.
            </p> */}

            <div className='mb-4'>
              <label htmlFor='name' className='text-xs text-white font-semibold'>
                Name
              </label>
              <input
                type='text'
                id='name'
                name='name'
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='email' className='text-xs text-white font-semibold'>
                Email
              </label>
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='message' className='text-xs text-white font-semibold'>
                Message
              </label>
              <textarea
                id='message'
                name='message'
                rows='4'
                value={formData.message}
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                className='w-full px-3 py-2 border border-[#B36824]'
              />
            </div>

            {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
            <br />

            <button className='py-2 px-4 text-[#B36824] text-sm text-left rounded bg-white'>Submit</button>
          </form>
        </div>

        <div className='flex flex-col lg:flex-row gap-y-5 gap-x-20 items-center py-5'>
          <p className='flex items-center gap-x-2'>
            <PhoneOutlined /> 260 966805790
          </p>
          <p className='flex items-center gap-x-2'>
            <MailOutlined /> admin@sharesinproperties.com
          </p>

          <Button
            type='primary'
            size={"large"}
            onClick={openInWhatsApp}
            className='font-Lato text-base font-light bg-green-500'
            icon={<WhatsAppOutlined />}
          >
            Send Us A Message
          </Button>
        </div>
      </div>
    </div>
  )
}

export default HouseDescription
