import React from "react"
import image1 from "../../img/client1.jpg"
import image2 from "../../img/client2.jpg"
import image3 from "../../img/client3.jpg"
import image4 from "../../img/client4.jpg"

function Testimony() {
  return (
    <div className='w-10/12 mx-auto py-20'>
      <p className='lg:text-4xl text-3xl text-right font-bold leading-tight capitalize mb-5'>
        See our Customer <span className='text-[#B36824] leading-tight'>Reviews</span>
      </p>
      <hr className='h-[10px] w-[200px] ml-auto bg-[#B36824] border border-[#B36824] mb-10' />

      <div className='flex flex-wrap justify-between gap-y-32 py-20'>
        <div className='w-full lg:w-5/12 bg-gray-100 py-5 px-10 relative'>
          <div className='absolute -top-10 '>
            <img src={image1} alt='person' className='h-20 w-20 rounded-full object-cover' />
          </div>

          <div className='mt-10 mb-5'>
            <p className='text-base text-[#B36824] font-semibold mb-2'>Grace Banda</p>

            <p className='text-base font-light text-sm'>
              "SharesIn Properties Limited made buying our dream home effortless! Their expertise and commitment to
              excellence, from understanding our needs to handling the entire purchase process, truly set them apart."
            </p>
          </div>
        </div>

        <div className='w-full lg:w-5/12 bg-gray-100 py-5 px-10 relative'>
          <div className='absolute -top-10 '>
            <img src={image2} alt='person' className='h-20 w-20 rounded-full object-cover' />
          </div>

          <div className='mt-10 mb-5'>
            <p className='text-base text-[#B36824] font-semibold mb-2' mt-10>
              Bwalya Chilufya
            </p>

            <p className=' text-base font-light text-sm'>
              "Investing with SharesIn Properties Limited opened up new possibilities for me. The shared ownership model
              allowed me to enter the property market without the full financial commitment. A smart and rewarding
              choice."
            </p>
          </div>
        </div>

        <div className='w-full lg:w-5/12 bg-gray-100 py-5 px-10 relative'>
          <div className='absolute -top-10 '>
            <img src={image3} alt='person' className='h-20 w-20 rounded-full object-cover' />
          </div>

          <div className='mt-10 mb-5'>
            <p className='text-base text-[#B36824] font-semibold mb-2' mt-10>
              Peter Choomba
            </p>

            <p className=' text-base font-light text-sm'>
              "Hands down, the property management services at SharesIn Properties Limited are top-notch. My investments
              are flourishing, thanks to their meticulous tenant screening and proactive maintenance. Trustworthy and
              reliable!"
            </p>
          </div>
        </div>

        <div className='w-full lg:w-5/12 bg-gray-100 py-5 px-10 relative'>
          <div className='absolute -top-10 '>
            <img src={image4} alt='person' className='h-20 w-20 rounded-full object-cover' />
          </div>

          <div className='mt-10 mb-5'>
            <p className='text-base text-[#B36824] font-semibold mb-2' mt-10>
              Susan Phiri
            </p>

            <p className=' text-base font-light text-sm'>
              "SharesIn Properties Limited exceeded my expectations in every aspect. Their diverse range of services,
              from outright purchases to shared ownership and property management, provides a holistic solution for
              anyone venturing into the real estate market. Highly recommended!"
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimony
