import React, { useState } from "react"

const MortgageCalculator = () => {
  const [principal, setPrincipal] = useState("")
  const [interestRate, setInterestRate] = useState("")
  const [loanTerm, setLoanTerm] = useState("")
  const [monthlyPayment, setMonthlyPayment] = useState(null)

  const handleCalculate = () => {
    const p = parseFloat(principal)
    const r = parseFloat(interestRate) / 100 / 12
    const n = parseInt(loanTerm) * 12

    if (p && r && n) {
      const monthlyPayment = (p * r) / (1 - Math.pow(1 + r, -n))
      setMonthlyPayment(monthlyPayment.toFixed(2))
    }
  }

  return (
    <div className="w-full mt-10 p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl text-center text-[#B36824] font-normal mb-10">Mortgage Calculator</h2>
      <div className="mb-5">
        <label className="block text-sm mb-2">
          Loan Amount (K):
          <input
            type="number"
            className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
          />
        </label>
      </div>

      <div className="mb-5">
        <label className="block text-sm mb-2">
          Annual Interest Rate (%):
          <input
            type="number"
            step="0.1"
            className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
          />
        </label>
      </div>

      <div className="mb-5">
        <label className="block text-sm mb-2">
          Loan Term (Years):
          <input
            type="number"
            className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-blue-500"
            value={loanTerm}
            onChange={(e) => setLoanTerm(e.target.value)}
          />
        </label>
      </div>

      <div className="mb-5">
        <button
          className="bg-[#B36824] text-white py-2 px-4 rounded-md focus:outline-none focus:shadow-outline-blue"
          onClick={handleCalculate}
        >
          Calculate
        </button>
      </div>

      {monthlyPayment !== null && (
        <div className="py-5">
          <p className="text-xl text-center"><span className="font-semibold">Monthly Payment:{"  "}</span>K{monthlyPayment}</p>
        </div>
      )}
    </div>
  )
}

export default MortgageCalculator
