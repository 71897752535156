import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { deleteSubcriber } from "../firebase"
import Loader from "../components/loader"

function Unsubscribe() {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  const unsubscribe = async (SubcriberId) => {
    try {
      await deleteSubcriber(SubcriberId)
    } catch (err) {
      alert("error deleting subcriber", err)
    }
  }

  useEffect(() => {
    unsubscribe(id)
  })


  return (
    <div className='w-11/12 w-10/12 mx-auto py-10'>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <p className='font-bold mb-5'>Unsubscribe Successful</p>

          <p>
            We're sorry to see you go. Your email has been successfully unsubscribed from our newsletter. If you ever
            change your mind, feel free to resubscribe anytime. Thank you for being a part of our community.
          </p>
        </div>
      )}
    </div>
  )
}

export default Unsubscribe
