import React from "react"
import logo from "../img/logo1.png"

const Loader = () => {
  return (
    <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50'>
      <div class='flex-col gap-4 w-full flex items-center justify-center'>
        <div class='w-28 h-28 border-8 text-[#B36824] text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-[#B36824] rounded-full'>
          <img src={logo} alt='logo' />
        </div>
        <p className="mt-2 text-[#B36824]">Loading...</p>
      </div>
    </div>
  )
}

export default Loader
