import React from "react";
import house from "../../img/house.jpg";
import { PushpinTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";

function HouseListing({ house }) {
  return (
    <div className="">
      <Link to={`/view_house/${house.id}`}>
        <div style={{ position: "relative" }}>
          <img
            src={house.images[0].file}
            alt="house"
            className="h-64 w-full object-cover"
          />
          {(house.sold === "true") && (
            <div
              className="absolute top-0 left-0 bg-blue-700 text-white font-bold px-5 py-1"
             
            >
              <span>Sold</span>
            </div>
          )}
        </div>

        <div className="py-2">
          <p className="text-xl text-black font-semibold mb-5 line-clamp-1 capitalize">
            {house.houseName}
          </p>

          <p className="text-base line-clamp-2 font-light mb-1">
            {house.houseDescription}
          </p>

          <div className="flex justify-between">
            <p className="text-base font-normal text-[#B36824] capitalize flex items-center gap-1">
              {/* <PushpinTwoTone twoToneColor='#B36824' /> */}
              {house.location}
            </p>
            <p className="text-base text-[#B36824]">{house.price}</p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default HouseListing;
