import React from "react"
import { ShopTwoTone, DollarTwoTone, ReconciliationTwoTone } from "@ant-design/icons"

function Services() {
  return (
    <div className='w-11/12 lg:w-10/12 mx-auto py-32'>
      <p className='lg:text-4xl text-3xl font-bold leading-tight capitalize mb-5'>
        We Excel in These <span className='text-[#B36824] leading-tight'>Services</span>
      </p>
      <hr className="h-[10px] w-[200px] bg-[#B36824] border border-[#B36824] mb-10"/>

      <p className='pb-20 font-light'>
        Share your preferences, and we'll handle the seamless property purchase process, offering shared ownership for
        value and expert management for consistent returns.
      </p>

      <div className='flex flex-col lg:flex-row gap-y-10 gap-10 py-0'>
        <div className='w-full lg:w-4/12 flex flex-col items-start bg-[#B36824] px-5 py-10'>
          <DollarTwoTone twoToneColor='#B36824' className='text-2xl mb-5 p-5 rounded-full bg-amber-50' />

          <div className='flex flex-col mt-10'>
            <p className='text-xl text-white font-semibold mb-5 pb-2 border-b border-white'>Outright Purchases</p>

            <p className=' text-base text-white font-light'>
              Tell us your desired property or budget, and we'll find the perfect match for you. Sit back and let us
              handle the purchase process, ensuring a seamless and stress-free experience.
            </p>
          </div>
        </div>

        <div className='w-full lg:w-4/12 flex flex-col items-start bg-[#B36824] px-5 py-10'>
          <ReconciliationTwoTone twoToneColor='#B36824' className='text-2xl mb-5 p-5 rounded-full bg-amber-50' />

          <div className='flex flex-col mt-10'>
            <p className='text-xl text-white font-semibold mb-5 pb-2 border-b border-white'>Shared Ownership</p>

            <p className=' text-base text-white font-light'>
              Invest in high-value properties through our innovative shared ownership model. Enjoy the benefits of
              property ownership without the full financial burden. Let's build wealth together!
            </p>
          </div>
        </div>

        <div className='w-full lg:w-4/12 flex flex-col items-start bg-[#B36824] px-5 py-10'>
          <ShopTwoTone twoToneColor='#B36824' className='text-2xl mb-5 p-5 rounded-full bg-amber-50' />

          <div className='flex flex-col mt-10'>
            <p className='text-xl text-white font-semibold mb-5 pb-2 border-b border-white'>Property Management</p>

            <p className=' text-base text-white font-light'>
              Maximize the value of your property investments with our professional property management services. We'll
              take care of tenant screening, maintenance, and everything in between, ensuring your properties generate
              consistent returns.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
